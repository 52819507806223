.hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap:2rem;
    flex-direction: column;
}
.rigth-h{
    flex: 1;
    position: relative;
    background-color: var(--lightblue);
}
.the-best-ad{
    margin-top: 4rem;
    background-color:#363d42 ;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    counter-reset: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content:flex-start ;

}
.the-best-ad>div{
   position: absolute;
   background-color: var(--orange);
   width: 5.4rem;
   height: 80%;
   left: 8px;
   border-radius: 3rem;
   z-index: 1;
}
.the-best-ad>span{
    z-index: 2;
}
.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: #fff;
    text-overflow: inherit;
}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}
.figure{
    display: flex;
    gap:2rem;
}
.figure>div{
    display: flex;
    flex-direction: column;
}
.figure>div>span:nth-of-type(1){
    color: #fff;
    font-size: 2rem;
}
.figure>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform:uppercase ;
}
.hero-button{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}
.hero-button>:nth-child(1){
    color: #fff;
    background-color: var(--orange);
    width: 8rem;
}
.hero-button>:nth-child(2){
    color: #fff;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}
.rigth-h>.btn{
    background-color: #3c3f45;
    position: absolute;
    right: 1rem;
    top: 1rem;
    /* color: var(--orange); */
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}
.heart-rate>img{
    width: 2rem;
}
.heart-rate>:nth-child(2){
    color: var(--gray);
}
.heart-rate>:nth-child(3){
    color: #fff;
    font-size: 1.5rem;
}
.hero-image{
    position: absolute;
    top: 10rem;
    right: 8rem;
    /* width: 23rem; */
    width: 31rem;
}
.hero-img-back{
   position: absolute;
   top: 4rem;
   right: 20rem;
   z-index: -1;
   width: 15rem;
}
.calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 32rem;
    right: 28rem;
}
.calories>img{
    width: 3rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div>:nth-child(1){
    color: var(--gray);
}
.calories>div>:nth-child(2){
    color: #fff;
    font-size: 1.1rem;
}
.blur-h{
  width: 22rem;
  height: 30rem;
  left: 0;
}

.blur-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background-color: orange;
}
.blur-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: #4b68de;
}

@media screen and (max-width:768px){
    .hero{
        flex-direction: column;
    }
    .blur-h{
        width: 14rem;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-items: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .hero-button{
        justify-content: center;
    }
    .figure>div>span:nth-of-type(1){
        font-size: large;
    }
    .figure>div>span:nth-of-type(2){
        font-size: small;
    }
    .rigth-h{
        position: relative;
        background: none;
    }
    .heart-rate{
        left: 1rem;
        top: 2rem;
    }
    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
    }
    .calories>img{
        width: 2rem;
    }
    .calories>div>:nth-child(2){
        color: #fff;
        font-size: 1rem;
    }
    .hero-image{
        position: relative;
        width: 15rem;
        left: 7rem;
        top: 4rem;
        align-items: center;
    }
    .hero-img-back{
        width: 15rem;
        left: 2rem;
        top: 0rem;
    }
}