.testimonial{
  padding: 0rem 2rem 0;
  margin-right: 3%;
  display: flex;
  gap:2rem;
}
.left-te{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
    grid-auto-rows: 1fr;
}
.left-te>img{
    object-fit: cover;
}
.left-te>:nth-child(1){
    width: auto;
    grid-row: 2/6;
    height: 28rem;
    margin-left: 20%;
}
.left-te>:nth-child(2){
   width: auto;
   height: 16rem;
   grid-column: 2/4;
}
.left-te>:nth-child(3){
    width: 17rem;
    grid-column: 2/3;
    grid-row: 2;
}
.left-te>:nth-child(4){
  width: 11rem;
  grid-row: 2;
  grid-column:3/4 ;
}
.right-te{
    flex: 1 1;
    /* text-transform: uppercase; */
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-te>span{
    font-weight: bold;
    color: var(--orange);
}
.right-te>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-te{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
}
.details-te>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}
.details-te>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners>img{
    width: 2.5rem;
}
@media screen and (max-width:768px){
    .testimonial{
        flex-direction: column;
    }
    .left-te{
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .left-te>img {
        display:none;
    }
    .left-te>:nth-child(1){
        width: 7rem;
        height: 17rem;
    }
    .left-te>:nth-child(2){
        width: 15rem;
        height: 10rem;
    }
    .left-te>:nth-child(1){
        width: 7rem;
        
    }
    .left-te>:nth-child(1){
        width: 7rem;
        height: 6rem;
    }
}
